var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-skeleton",
    { attrs: { active: "", loading: _vm.loading, paragraph: { rows: 17 } } },
    [
      _c(
        "a-card",
        [
          _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, xl: 12 } },
                [
                  _c(
                    "area-chart-ty",
                    _vm._b({}, "area-chart-ty", _vm.memory, false)
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, xl: 12 } },
                [
                  _c(
                    "area-chart-ty",
                    _vm._b({}, "area-chart-ty", _vm.key, false)
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("Redis 详细信息")]),
          _c("a-table", {
            attrs: {
              loading: _vm.tableLoading,
              columns: _vm.columns,
              dataSource: _vm.redisInfo,
              pagination: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }